.competition_info {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 5px;
  background: transparent linear-gradient(180deg, #313132 0%, #28282b 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .competition_info_header {
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    align-items: center;
  }

  .competition_info_title {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  .competition_info_finished_label {
    font-style: italic;
    font-size: 14px;
    margin-left: 20px;
  }

  .competition_info_finished_icon {
    margin-left: 5px;
    font-size: 16px;
  }

  .competition_info_subtitle {
    color: #fff;
    font-weight: bold;
    font-size: 12px;
  }

  .competition_info_text {
    color: #fff;
    font-size: 12px;
    display: inline;
    margin-left: 10px;
  }

  .competition_info_grid {
    flex: 1;
    padding: 5px;
  }
}
