.competition_podium {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 10px;
  background: transparent linear-gradient(180deg, #313132 0%, #28282b 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;

  .competition_podium_title {
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 14px;
    height: '10%';
  }

  .competition_podium_name {
    /*  font-size: 12px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
