.profile-container {
  background-color: #1b1b1b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  & .profile-content {
    padding: 75px 30px 50px;

    & h4 {
      color: #ebe784 !important;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
    }

    & .avatar-container {
      text-align: center;
    }

    & .avatar {
      width: 100px;
      height: 100px;
    }

    & .edit-avatar-button {
      background-color: #ffe784;

      & .button-icon {
        color: #474444;
        font-size: 16px;
      }
    }

    & .edit-avatar-button:hover {
      background-color: #c6b449;
    }

    & .MuiOutlinedInput-root {
      color: #474444;
      background: rgba(226, 227, 228, 8);
      height: 50px;

      & .country-code {
        margin-right: 10px;
        font-weight: bold;
      }
    }

    & .MuiFormLabel-root {
      margin-bottom: 5px;
      color: #fff;
    }
  }
}
