* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}
.Main {
  height: 100vh;
  width: 100%;
}
.primaryColor {
  color: #ebe784;
}

// .App {
//   overflow-y: scroll;
//   overflow-x: scroll;
// }
