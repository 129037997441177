.last_registrations {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #313132 0%, #161618 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;

  & .last_registrations_title {
    color: #fff;
    font-weight: bold;
    margin: 20px;
    font-size: 14px;
  }

  & .last_registrations_content {
    flex: 1;
    height: 80%;
  }

  & .registration_card {
    height: 33%;
    width: 100%;
    border-radius: 0;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .card_header {
      height: 100%;
      color: #fff;
    }

    & .registration_avatar {
      height: 4vh;
      width: 4vh;
    }

    & .registration_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 20%;
    }

    & .reg_category_container {
      width: 95%;
      border-radius: 5px;
      background-color: #efc862;
      text-align: center;
    }

    & .reg_category {
      color: #161618;
      font-size: 8px;
      font-weight: bold;
    }

    & .registration_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-left: 5px;
      padding-right: 5px;
      width: 80%;

      & .reg_info_top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }

      & .time_since {
        color: #fff;
        font-size: 10px;
        opacity: 0.7;
      }

      & .reg_name {
        color: #fff;
        font-size: 13px;
      }

      & .reg_amount {
        color: #fff;
        font-size: 10px;
        opacity: 0.5;
      }

      & .reg_comment {
        color: #fff;
        font-size: 10px;
        opacity: 0.5;
      }
    }
  }
}
