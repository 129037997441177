* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

.MuiGrid-grid-xs-11 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1 0 auto;
}
