.total_progress {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  height: 100%;
  width: 100%;
  padding: 20px;
  background: transparent linear-gradient(180deg, #313132 0%, #161618 100%) 0%
    0% no-repeat padding-box;
  & .total_progress_title {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  & .doughnut_container {
    position: relative;
    flex: 1;
  }
  & .centered-text {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 45%;
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    opacity: 0.9;

    & .total {
      font-size: 10px;
      opacity: 0.5;
    }
  }
}
