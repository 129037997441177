.team_trend {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(180deg, #313132 0%, #161618 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  overflow: hidden;

  & .team_trend_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 20%;

    & .team_trend_title {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      width: 30%;
    }

    & .team_trend_label_container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 60%;

      & .label {
        display: flex;
        flex-direction: row;
        align-items: center;

        & .label_dot {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
        }

        & .label_text {
          color: #fff;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }

    & .team_trend_period_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;

      & .team_trend_period {
        display: flex;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #d1d1d1;
        border-width: thin;
        border-radius: 4px;
        width: 75px;
        padding: 2px;

        & .period_text {
          color: #d1d1d1;
          font-size: 10px;
        }
      }
    }
  }

  & .team_trend_chart {
    height: 80%;
    padding: 10px;
  }
}
