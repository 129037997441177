.competition_progress {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: transparent linear-gradient(180deg, #313132 0%, #28282b 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .competition_progress_title {
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 20px;
    font-size: 14px;
  }

  & .finished_animation {
    animation: winner-blink-animation 1s steps(2, start) infinite;
    -webkit-animation: winner-blink-animation 1s steps(2, start) infinite;
  }

  & .finished_text_animation {
    font-size: 12px;
    font-weight: bolder;
    animation: winner-text-blink-animation 1s steps(2, start) infinite;
    -webkit-animation: winner-text-blink-animation 1s steps(2, start) infinite;
  }
}

@keyframes winner-blink-animation {
  to {
    background-color: rgba(223, 189, 105, 0.7);
  }
}

@keyframes winner-text-blink-animation {
  to {
    color: #fff;
  }
}

@-webkit-keyframes winner-blink-animation {
  to {
    background-color: rgba(223, 189, 105, 0.7);
  }
}

@-webkit-keyframes winner-text-blink-animation {
  to {
    color: #fff;
  }
}
