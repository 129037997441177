.trophies_container {
  background-color: #1b1b1b;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.trophies_content {
  padding: 75px 50px 50px;

  & h4 {
    color: #ebe784 !important;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  & h6 {
    color: #ebe784;
    font-size: 16px;
  }

  & .trophy_timestamp {
    color: #fff;
  }

  & .trophy_placeholder {
    color: #fff;
    font-style: italic;
  }

  & .avatar-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #ebe784;
    border-width: 0.1px;
    border-bottom-style: solid;
    padding-bottom: 20px;
  }

  & .avatar {
    width: 100px;
    height: 100px;
  }
}
