.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.dash--head {
  background: transparent linear-gradient(98deg, #242424 0%, #000 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  text-align: center;
  margin-bottom: 11px;

  & .name--of--username {
    font-size: 28px;
    font-weight: 500;
    padding-top: 100px;
    margin-left: 40px;
  }

  & .edit-button {
    margin-left: 10px;
  }

  & .user--email {
    font-size: 13px;
    color: #bcbcbc;
  }

  & .username--results {
    display: flex;
    width: 600px;
    justify-content: space-around;
    align-items: center;
    margin: 40px auto 0;
    padding: 0 0 10px;

    @media (max-width: 500px) {
      width: 90%;
    }
  }

  & .left--results h4,
  .right--results h4 {
    padding-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 500;
  }

  & .left--results span,
  .right--results span {
    font-size: 22px;
  }
}

.dash--body {
  background-color: #1b1b1b;
  //   clip-path: polygon(50% 0%, 53% 3%, 100% 3%, 100% 100%, 0 100%, 0 3%, 47% 3%);
  min-height: 80vh;
  border-bottom: 1px solid #292828;

  .user--body {
    display: flex;
    width: 600px;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;

    @media (max-width: 500px) {
      width: 90%;
    }

    & select {
      width: 80%;
      border: none;
      background-color: transparent;
      color: #bcbcbc;
      letter-spacing: 0.7px;

      &:focus,
      :active,
      :focus-within,
      :target,
      :visited,
      :focus-visible {
        border: none !important;
      }

      & option {
        color: #000;

        &:focus,
        :active,
        :focus-within,
        :target,
        :visited,
        :focus-visible {
          border: none !important;
        }
      }
    }

    & .line {
      position: relative;
      margin: 10px auto 13px;
      width: 76%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & .line1 {
        position: absolute;
        width: 70%;
        background-color: #c6b449;
        border: none;
        height: 2px;
      }

      & .line2 {
        width: 100%;
        background-color: #5c5858;
        border: none;
        height: 1.5px;
      }
    }

    & .square--grid {
      position: relative;

      & .MuiAvatar-root {
        color: #fff;
        position: absolute;
        width: 100px;
        height: 100px;
        left: 50%;
        transform: translate(-42%, -95%);
        //bottom: 100%;
      }
    }

    & .square {
      transform: rotate(45deg) translateX(-63%);
      border: 15px solid #292929;
      width: 150px;
      border-radius: 3px;
      margin: 0 auto;
      height: 150px;
      position: absolute;
      overflow: hidden;
      top: -52px;
      left: 50%;

      @media (max-width: 500px) {
        width: 125px;
        height: 125px;
      }
      //background-image: url(../../../assets/images/user.jpg);
      //background-color: #6c6b6b;
      /* background-repeat: no-repeat;
      background-size: cover;
      background-position: center; */

      & .user {
        background-image: url(../../../assets/images/user.jpg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
      }
    }
  }

  & .content {
    padding-top: 75px;
  }

  & .goal_progress_container_left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  & .goal_progress_container_right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & .goal_progress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 10vw;

    & .current_value {
      color: rgb(53, 162, 235);
      font-weight: 700;
      text-align: center;
    }

    & h6 {
      color: #ffe784;
    }

    & .caption {
      color: #fff;
    }
  }

  & .recent-sales {
    margin: 30px;
    padding: 30px;

    & .pagination {
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: center;

      & .MuiPaginationItem-root {
        color: #fff;
      }
    }

    & .placeholder-text {
      font-style: italic;
      font-size: 14px;
      opacity: 0.8;
      color: #fff;
      text-align: center;
    }

    & h4 {
      text-align: center;
      color: #ffe784;
    }

    & .subtitle {
      text-align: center;
      color: #fff;
    }

    & .user__body {
      padding: 10px 0;
      width: 100%;

      & .user__body--bg-color {
        display: flex;
        background-color: #52565a;
        width: 100%;
      }

      & .user__body--nr {
        background-color: #ffe784;
        display: flex;
        align-items: center;
        padding: 0 3px;
      }

      & .user__body--profile {
        background-color: #66707b;
        clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px;
          border-radius: 50%;
        }
      }

      & .user__div {
        width: 90%;
        display: flex;
        flex-direction: column;

        padding: 5px 5px;

        & .user__results {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          width: 100%;

          & .registration_date_container {
            width: 20%;

            & .registration_time {
              color: #c6b449;
              font-size: 14px;
            }

            & .registration_date {
              color: #fff;
              font-size: 10px;
            }
          }

          & .registration_comment_container {
            width: 40%;

            & .registration_comment {
              color: #fff;
              font-weight: 600;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            & .registration_category {
              color: #c6b449;
              font-size: 14px;
            }
          }

          & .registration_value_container {
            width: 30%;

            & .registration_value {
              color: #c6b449;
              font-size: 14px;
            }
          }

          & .delete_button_container {
            width: 10%;

            & .delete_button {
              width: 100%;
              aspect-ratio: 1 / 1;
              height: auto;
            }
          }

          /* & h6 {
            color: #fff;
            font-size: 16px;
            line-height: normal;

            &:last-of-type {
              font-weight: 300;
            }
          } */
        }

        & .line__style {
          height: 10px;
          width: 85%;
          background-color: #ffe784;
          border-bottom-right-radius: 10px;
        }
      }
    }

    & .quantity__body {
      display: flex;
      line-height: normal;

      & span {
        margin-left: 3px;
      }
    }
  }

  & .team-comparison {
    margin: 30px;
    padding: 30px;

    & .placeholder-text {
      font-style: italic;
      font-size: 14px;
      opacity: 0.8;
      color: #fff;
      text-align: center;
    }

    & h4 {
      text-align: center;
      color: #ffe784;
    }

    & .subtitle {
      text-align: center;
      color: #fff;
    }
  }
}

.register-modal {
  background: linear-gradient(to bottom right, #474444, #181819);
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h5 {
    text-align: center;
    margin-bottom: 30px;
    color: #ebe784;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
  }

  & .MuiOutlinedInput-root {
    color: #474444;
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
    margin-bottom: 30px;
    width: 300px;
  }

  & .MuiFormLabel-root {
    text-align: left;
    align-self: left;
    width: 300px;
    margin-left: 5px;
    color: #ebe784;
  }
}

// ppppppppppppppppp

// .dash--head {
//     background-color: #000;
//     color: #fff;
//     text-align: center;
//     margin-bottom: 8px;
//     position: relative;
//     top: 60px;
//     text-align: center;
//     margin-bottom: 60px;

//     & h1 {
//       position: relative;
//       font-size: 28px;
//       font-weight: 500;
//       padding-top: 18px;
//       z-index: 5;
//     }

//     & span {
//       position: relative;
//     }

//     & .username--results {
//       display: flex;
//       width: 600px;
//       justify-content: space-around;
//       align-items: center;
//       margin: 20px auto 0;
//     }

//     & .left--results h4,
//     .right--results h4 {
//       padding-bottom: 8px;
//       text-transform: uppercase;
//       letter-spacing: 3px;
//       font-weight: 500;
//     }

//     & .triangle {
//       width: 0;
//       height: 0;
//       border-left: 100px solid transparent;
//       border-right: 100px solid transparent;
//       border-bottom: 100px solid rgb(255, 255, 255);
//     }

//     & .left--results span,
//     .right--results span {
//       font-size: 22px;
//     }
//   }

//   .dash--body {
//     background-color: #d64040;

//     height: 100vh;
//   }
