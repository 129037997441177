// Home page

.home {
  & .home__grid {
    display: flex;
    background: linear-gradient(to top left, #242424, #181819);
  }

  & .mini_title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  & .home__grid__2 {
    background: linear-gradient(to bottom right, #474444, #181819);
    height: 25vh;
  }

  & .home__grid__3 {
    background: linear-gradient(to bottom right, #fff, #f7f5c7);
    padding: 40px;

    h3 {
      font-weight: bolder;
      font-size: 30px;
      margin-bottom: 10px;
    }

    .MuiTypography-paragraph {
      line-height: 1.5;
      font-size: 20px;
    }
  }

  & .top_left_grid {
    //background: linear-gradient(to bottom right, #474444, #181819);
    background: transparent;
    height: 80vh;
    position: relative;

    // z-index: 3;
  }

  & .left__home {
    color: #fff;
    transform: translate(60px, 220px);
  }

  & .MuiTypography-h4 {
    font-size: 22px;
  }

  & .line {
    position: relative;
    margin: 10px 0px 13px;

    & .line1 {
      position: absolute;
      width: 20%;
      background-color: #998e4c;
      border: none;
      height: 1.5px;
    }

    & .line2 {
      width: 28%;
      background-color: #5c5858;
      border: none;
      height: 1.5px;
    }
  }

  & .MuiTypography-h6 {
    font-size: 30px;
    /* max-width: 80%; */
    color: #fff;
  }

  & .MuiTypography-paragraph {
    font-size: 16px;
    margin: 5px 0 0;
    color: #787878;
  }

  & .top_right_grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(0px, 220px);

    & .paragraph {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }
  }

  & .right__home {
    /* position: absolute;
    bottom: 60px;
    left: 60px;
    max-width: 280px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px;
    border-top-right-radius: 35px; */
    display: flex;
    flex-direction: column;
    align-items: center;

    transform: translate(0px, 220px);

    & .MuiTypography-h1 span {
      font-size: 25px;
      position: relative;
      bottom: 18px;
    }

    & .MuiTypography-paragraph {
      font-size: 15px;
      color: #fff;

      & span {
        text-decoration: underline;
      }
    }
  }

  & .MuiTypography-h1 {
    font-size: 45px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 1.5px;
    margin-bottom: 25px;
  }

  & .logo {
    width: 85%;
    object-fit: cover;
  }
}

#bg {
  position: fixed;
  width: 100%;
  left: 50%;
  top: 50%;
  height: 100vh;
  max-height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1001;
}
