.leaderboard_footer {
  display: flex;
  padding: 5px;
  height: 100%;
  width: 100%;
  background: transparent linear-gradient(180deg, #313132 0%, #161618 100%) 0%
    0% no-repeat padding-box;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
}
