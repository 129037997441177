.MuiGrid-grid-xs-1 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //background: linear-gradient(to bottom right, #474444, #181819);
  background: transparent linear-gradient(98deg, #242424 0%, #171717 100%) 0% 0%
    no-repeat padding-box;
  color: rgb(247, 244, 244);
  padding: 5px 0;
  border-right: 1px solid #979797;
  position: relative;
  z-index: 2;

  & a {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
  }
}

.header__icons {
  margin-top: auto;
  width: 100%;
  bottom: 10px;
  display: flex;
  flex-direction: column;

  & .icon_button {
    margin: 15px 0;
    font-size: 17px;
    color: rgb(247, 244, 244);
  }
}

.header {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo-header {
  top: 30px;
  width: 100px;
  object-fit: cover;
}

.logo {
  width: 75%;
  object-fit: cover;
}
